<template>
  <section>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: 0 }">
      <template #title>
      </template>
      <ul class="list settings-list">
        <li>
          <h6 class="list-header text-sm text-muted">Source data</h6>
        </li>
        <li>
          <textarea class="ant-input data" v-model="input"></textarea>
        </li>
        <li>
          <a-switch v-model="isLive"/>
          <span>Live mode</span>
        </li>
        <li v-show="!isLive">
          <a-button type="primary" @click="encode">
            encode
          </a-button>
        </li>
        <li>
          <h6 class="list-header text-sm text-muted">Encoded data</h6>
        </li>
        <li>
          <textarea class="ant-input data" v-model="encoded"></textarea>
        </li>
      </ul>
    </a-card>
  </section>
</template>

<script>

export default ({
  data: function () {
    return {
      input: '',
      encoded: '',
      isLive: true,
    }
  },
  mounted() {
    if (this.$route.hash && this.$route.hash.length > 1) {
      this.input = this.$route.hash.substr(1);
    }
  },
  methods: {
    encode: function () {
      this.encoded =  btoa(unescape(encodeURIComponent(this.input)))
    }
  },
  watch: {
    input(){
      if (this.isLive){
        this.encode()
      }
    }
  }
})

</script>

<style lang="scss">
textarea.ant-input.data {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

.settings-list > li .ant-btn-primary span {
  color: #fff;
  margin-left: 0;
}
</style>